import {
  ApartmentBedroom,
  ApartmentCondition,
  ApartmentFloorZone,
} from '@p1/types';
import i18n from '../../i18n';

export const getApartmentBedroomText = (bed: ApartmentBedroom) => {
  switch (bed) {
    case ApartmentBedroom.Studio:
      return i18n.t('apartment_bedroom_studio');
    default:
      return i18n.t('apartment_bedroom_num', { num: bed.toString() });
  }
};

export const BEDROOM_OPTIONS = [
  ApartmentBedroom.Studio,
  ApartmentBedroom['1BR'],
  ApartmentBedroom['2BR'],
  ApartmentBedroom['3BR'],
  ApartmentBedroom['4BR'],
  ApartmentBedroom['5BR'],
  ApartmentBedroom['6BR'],
  ApartmentBedroom['7BR'],
  ApartmentBedroom['8BR'],
  ApartmentBedroom['9BR'],
  ApartmentBedroom['10BR'],
].map((val) => ({
  value: val,
  label: getApartmentBedroomText(val),
}));

export const APARTMENT_CONDITION_TEXT = {
  [ApartmentCondition.Furnished]: i18n.t('apartment_condition_furnished'),
  [ApartmentCondition.SemiFurnished]: i18n.t(
    'apartment_condition_semi_furnished'
  ),
  [ApartmentCondition.Unfurnished]: i18n.t('apartment_condition_unfurnished'),
};

export const APARTMENT_CONDITION_OPTIONS = [
  ApartmentCondition.Furnished,
  ApartmentCondition.SemiFurnished,
  ApartmentCondition.Unfurnished,
].map((val) => ({
  value: val,
  label: APARTMENT_CONDITION_TEXT[val],
}));

export const getApartmentFloorZoneText = (floorZone: ApartmentFloorZone) => {
  switch (floorZone) {
    case ApartmentFloorZone.Ground:
      return i18n.t('apartment_floor_zone_ground');
    case ApartmentFloorZone.Low:
      return i18n.t('apartment_floor_zone_low');
    case ApartmentFloorZone.Mid:
      return i18n.t('apartment_floor_zone_mid');
    case ApartmentFloorZone.High:
      return i18n.t('apartment_floor_zone_high');
    case ApartmentFloorZone.Penthouse:
      return i18n.t('apartment_floor_zone_penthouse');
  }
  return '';
};

export const FLOOR_ZONE_OPTIONS = [
  ApartmentFloorZone.Ground,
  ApartmentFloorZone.Low,
  ApartmentFloorZone.Mid,
  ApartmentFloorZone.High,
  ApartmentFloorZone.Penthouse,
].map((val) => ({
  value: val,
  label: getApartmentFloorZoneText(val),
}));

export const APARTMENT_ROOM_FACILITY_OPTIONS = [
  { key: 'tv', text: i18n.t('facility_tv') },
  { key: 'ac', text: i18n.t('facility_ac') },
  { key: 'wifi', text: i18n.t('facility_wifi') },
  { key: 'kitchen', text: i18n.t('facility_kitchen') },
  { key: 'stove', text: i18n.t('facility_stove') },
  { key: 'fridge', text: i18n.t('facility_fridge') },
  { key: 'microwave', text: i18n.t('facility_microwave') },
  { key: 'waterDispenser', text: i18n.t('facility_water_dispenser') },
  { key: 'washingMachine', text: i18n.t('facility_washing_machine') },
  { key: 'bathtub', text: i18n.t('facility_bathtub') },
  { key: 'waterHeater', text: i18n.t('facility_water_heater') },
];

export const APARTMENT_PROPERTY_FACILITY_OPTIONS = [
  { key: 'swimmingPool', text: i18n.t('facility_swimming_pool') },
  { key: 'jacuzzi', text: i18n.t('facility_jacuzzi') },
  { key: 'gym', text: i18n.t('facility_gym') },
  { key: 'sauna', text: i18n.t('facility_sauna') },
  { key: 'tennisCourt', text: i18n.t('facility_tennis_court') },
  { key: 'basketballCourt', text: i18n.t('facility_basketball_court') },
  { key: 'joggingTrack', text: i18n.t('facility_jogging_track') },
  { key: 'playground', text: i18n.t('facility_playground') },
  { key: 'garden', text: i18n.t('facility_garden') },
  { key: 'minimarket', text: i18n.t('facility_minimarket') },
  { key: 'laundry', text: i18n.t('facility_laundry') },
  { key: 'restaurant', text: i18n.t('facility_restaurant') },
  { key: 'billiard', text: i18n.t('facility_billiard') },
  { key: 'tableTennis', text: i18n.t('facility_table_tennis') },
  { key: 'atm', text: i18n.t('facility_atm') },
  { key: 'functionRoom', text: i18n.t('facility_function_room') },
  { key: 'theaterRoom', text: i18n.t('facility_theater_room') },
  { key: 'library', text: i18n.t('facility_library') },
  { key: 'mailbox', text: i18n.t('facility_mailbox') },
  { key: 'cctv', text: i18n.t('facility_cctv') },
  { key: 'security', text: i18n.t('facility_security') },
  { key: 'privateLift', text: i18n.t('facility_private_lift') },
  { key: 'privateAccessCard', text: i18n.t('facility_private_access_card') },
];
