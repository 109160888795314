if (process.env.NODE_ENV === 'development') {
  // window.__MFE_shell__ = 'http://localhost:6900/remoteEntry.js';
  // window.__MFE_auth__ = 'http://localhost:6901/remoteEntry.js';
  // window.__MFE_home__ = 'http://localhost:6902/remoteEntry.js';
  // window.__MFE_listing__ = 'http://localhost:6903/remoteEntry.js';
  // window.__MFE_profile__ = 'http://localhost:6904/remoteEntry.js';
  window.__ADS_CENTER_API__ = 'http://200.69.21.164:8080';
}

import('./bootstrap');

export {};
