import i18n from '../../i18n';

export const HOUSE_FACILITY_OPTIONS = [
  { key: 'tv', text: i18n.t('facility_tv') },
  { key: 'ac', text: i18n.t('facility_ac') },
  { key: 'wifi', text: i18n.t('facility_wifi') },
  { key: 'telephone', text: i18n.t('facility_telephone') },
  { key: 'stove', text: i18n.t('facility_stove') },
  { key: 'fridge', text: i18n.t('facility_fridge') },
  { key: 'oven', text: i18n.t('facility_oven') },
  { key: 'waterHeater', text: i18n.t('facility_water_heater') },
  { key: 'garage', text: i18n.t('facility_garage') },
  { key: 'swimmingPool', text: i18n.t('facility_swimming_pool') },
];
