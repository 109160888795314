export enum Language {
  EN = 'en',
  ID = 'id',
}

export const createI18n = (
  dictionary: Record<Language, Record<string, string>>
) => {
  let currentLang = Language.ID;

  if (typeof window !== 'undefined') {
    const storageLang = window.localStorage.lang;
    if (Object.keys(dictionary).includes(storageLang)) {
      currentLang = storageLang;
    }
    window.localStorage.lang = currentLang;
  }

  const t = function (key: string, data?: Record<string, any>) {
    const dict = dictionary[currentLang];
    if (dict == null) {
      throw new Error('Language is missing');
    }
    let result = dict[key];
    if (result == null) {
      return key;
    }

    if (data) {
      Object.keys(data).forEach((dataKey) => {
        const value = data[dataKey];
        const template = `{${dataKey}}`;
        result = result.replaceAll(template, value);
      });
    }
    return result;
  };

  return {
    t,
  };
};
