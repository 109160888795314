export enum UserKind {
  PropertyAgent = 'property-agent',
  Owner = 'owner',
}

export type User = {
  id: string;
  email: string;
  name: string;
  kind: UserKind;
  dateJoin: number;
  contactNumber: string;
  whatsapp?: string;
  image?: {
    name: string;
    folder: string;
  } | null;
  slug?: string;
};

export enum UserRole {
  Member = 'member',
  Moderator = 'moderator',
  Admin = 'admin',
  SuperAdmin = 'superadmin',
  Writer = 'writer',
}
