import { LocationType } from '@p1/types';
import i18n from '../i18n';

export const LOCATION_TYPE_TEXT = {
  [LocationType.Province]: i18n.t('province'),
  [LocationType.City]: i18n.t('city'),
  [LocationType.Region]: i18n.t('region'),
  [LocationType.Property]: i18n.t('property'),
  [LocationType.LocationTag]: i18n.t('location_tag'),
};
