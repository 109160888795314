export enum Certificate {
  SHM = 'shm',
  HGB = 'hgb',
  STT = 'stt',
  HGU = 'hgu',
  AJB = 'ajb',
  HakPakai = 'hakPakai',
  Girik = 'girik',
  PPJB = 'ppjb',
  Adat = 'adat',
  Other = 'other',
}

export enum PremiumBumpType {
  Quota = 'quota',
  Period = 'period',
}

export enum Furnishing {
  Furnished = 'furnished',
  SemiFurnished = 'semi-furnished',
  Unfurnished = 'unfurnished',
}

export enum PublishStatus {
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export enum Price {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
  Sell = 'sell',
}

export type PublishData =
  | {
      status: PublishStatus.Pending | PublishStatus.Deleted;
    }
  | {
      status: PublishStatus.Published | PublishStatus.Inactive;
      datePublished: number;
    }
  | {
      status: PublishStatus.Rejected;
      rejectionReason: string;
    };
