export enum PropertyType {
  Apartment = 'apartment',
  House = 'house',
  Land = 'land',
  Kost = 'kost',
  Villa = 'villa',
  Office = 'office',
  Shophouse = 'shophouse',
  Warehouse = 'warehouse',
  Factory = 'factory',
  Kiosk = 'kiosk',
  BusinessPlace = 'business-place',
  Building = 'building',
  Hotel = 'hotel',
}
