export type ApiResponse<T> = {
  error?: number;
  errorMsg?: string;
  message?: number;
  data: T;
};

export enum ApiStatus {
  Ready = 'ready',
  Loading = 'loading',
  Error = 'error',
}

export type State<T> =
  | {
      status: ApiStatus.Loading;
    }
  | {
      status: ApiStatus.Ready;
      data: T;
    }
  | {
      status: ApiStatus.Error;
      error?: number;
    };
