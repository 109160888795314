import i18n from '../i18n';
import { UserKind, UserRole } from '@p1/types';

export const USER_KIND_TEXT = {
  [UserKind.PropertyAgent]: i18n.t('user_kind_property_agent'),
  [UserKind.Owner]: i18n.t('user_kind_owner'),
};

export const USER_KIND_OPTIONS = [UserKind.PropertyAgent, UserKind.Owner].map(
  (value) => ({
    value,
    label: USER_KIND_TEXT[value],
  })
);

export const USER_ROLE_TEXT = {
  [UserRole.Member]: i18n.t('user_role_member'),
  [UserRole.Moderator]: i18n.t('user_role_moderator'),
  [UserRole.Admin]: i18n.t('user_role_admin'),
  [UserRole.SuperAdmin]: i18n.t('user_role_super_admin'),
  [UserRole.Writer]: i18n.t('user_role_writer'),
};

export const USER_ROLE_OPTIONS = [
  UserRole.Member,
  UserRole.Moderator,
  UserRole.Admin,
  UserRole.SuperAdmin,
  UserRole.Writer,
].map((val) => ({
  value: val,
  label: USER_ROLE_TEXT[val],
}));
