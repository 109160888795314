import { Furnishing } from '@p1/types';
import i18n from '../i18n';

export const FURNISHING_TEXT = {
  [Furnishing.Furnished]: i18n.t('furnishing_furnished'),
  [Furnishing.SemiFurnished]: i18n.t('furnishing_semi_furnished'),
  [Furnishing.Unfurnished]: i18n.t('furnishing_unfurnished'),
};

export const FURNISHING_OPTIONS = [
  Furnishing.Furnished,
  Furnishing.SemiFurnished,
  Furnishing.Unfurnished,
].map((val) => ({
  value: val,
  label: FURNISHING_TEXT[val],
}));
