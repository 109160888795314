export type Province = {
  id: string;
  name: string;
};

export interface City {
  id: string;
  name: string;
  provinceId: string;
}

export type Region = {
  id: string;
  name: string;
  cityId: string;
  provinceId: string;
};

export enum LocationTagType {
  Area = 'area',
  Street = 'street',
  Building = 'building',
  Hospital = 'hospital',
  Campus = 'campus',
  School = 'school',
  Airport = 'airport',
  Station = 'station',
  Mall = 'mall',
  HousingComplex = 'housing-complex',
  Market = 'market',
  Landmark = 'landmark',
  Stop = 'stop',
  Other = 'other',
}

export enum LocationType {
  LocationTag = 'locationTag',
  City = 'city',
  Region = 'region',
  Property = 'property',
  Province = 'province',
}
