export enum ApartmentBedroom {
  Studio = 'studio',
  '1BR' = 1,
  '2BR' = 2,
  '3BR' = 3,
  '4BR' = 4,
  '5BR' = 5,
  '6BR' = 6,
  '7BR' = 7,
  '8BR' = 8,
  '9BR' = 9,
  '10BR' = 10,
}

export enum ApartmentCondition {
  Furnished = 'F',
  SemiFurnished = 'S',
  Unfurnished = 'U',
}

export enum ApartmentFloorZone {
  Ground = 'ground',
  Low = 'low',
  Mid = 'mid',
  High = 'high',
  Penthouse = 'penthouse',
}
