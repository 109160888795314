export enum ListingStatus {
  Pending = 'pending',
  Published = 'published',
  Rejected = 'rejected',
  Inactive = 'inactive',
  Deleted = 'deleted',
}

export enum IklanType {
  Rent = 'rent',
  Sell = 'sell',
}

export enum RentPeriod {
  Daily = 'daily',
  Weekly = 'weekly',
  Monthly = 'monthly',
  Yearly = 'yearly',
}

export enum PremiumListingLocation {
  Home = 'home',
  List = 'list',
}
