import i18n from '../../i18n';

export enum KostRoomFacility {
  InnerBathroom = 'innerBathroom',
  SittingToilet = 'sittingToilet',
  WaterHeater = 'waterHeater',
  Bed = 'bed',
  Wardrobe = 'wardrobe',
  TV = 'tv',
  AC = 'ac',
  Fan = 'fan',
  Window = 'window',
  Table = 'table',
  Chair = 'chair',
}

export const KOST_ROOM_FACILITY_TEXT = {
  [KostRoomFacility.InnerBathroom]: i18n.t('facility_inner_bathroom'),
  [KostRoomFacility.SittingToilet]: i18n.t('facility_sitting_toilet'),
  [KostRoomFacility.WaterHeater]: i18n.t('facility_water_heater'),
  [KostRoomFacility.Bed]: i18n.t('facility_bed'),
  [KostRoomFacility.Wardrobe]: i18n.t('facility_wardrobe'),
  [KostRoomFacility.TV]: i18n.t('facility_tv'),
  [KostRoomFacility.AC]: i18n.t('facility_ac'),
  [KostRoomFacility.Fan]: i18n.t('facility_fan'),
  [KostRoomFacility.Window]: i18n.t('facility_window'),
  [KostRoomFacility.Table]: i18n.t('facility_table'),
  [KostRoomFacility.Chair]: i18n.t('facility_chair'),
};

export const KOST_ROOM_FACILITY_OPTIONS = [
  KostRoomFacility.InnerBathroom,
  KostRoomFacility.SittingToilet,
  KostRoomFacility.WaterHeater,
  KostRoomFacility.Bed,
  KostRoomFacility.Wardrobe,
  KostRoomFacility.TV,
  KostRoomFacility.AC,
  KostRoomFacility.Fan,
  KostRoomFacility.Window,
  KostRoomFacility.Table,
  KostRoomFacility.Chair,
].map((key) => ({
  key,
  text: KOST_ROOM_FACILITY_TEXT[key],
}));

export enum KostCommonFacility {
  Wifi = 'wifi',
  CarParking = 'carParking',
  MotorcycleParking = 'motorcycleParking',
  WashingMachine = 'washingMachine',
  Laundry = 'laundry',
  Security = 'security',
  PrayerRoom = 'prayerRoom',
  Fridge = 'fridge',
  TV = 'tv',
  WaterDispenser = 'waterDispenser',
  LivingRoom = 'livingRoom',
  Gym = 'gym',
  SwimmingPool = 'swimmingPool',
}

export const KOST_COMMON_FACILITY_TEXT = {
  [KostCommonFacility.Wifi]: i18n.t('facility_wifi'),
  [KostCommonFacility.CarParking]: i18n.t('facility_car_parking'),
  [KostCommonFacility.MotorcycleParking]: i18n.t('facility_motorcycle_parking'),
  [KostCommonFacility.WashingMachine]: i18n.t('facility_washing_machine'),
  [KostCommonFacility.Laundry]: i18n.t('facility_laundry'),
  [KostCommonFacility.Security]: i18n.t('facility_security'),
  [KostCommonFacility.PrayerRoom]: i18n.t('facility_prayer_room'),
  [KostCommonFacility.Fridge]: i18n.t('facility_fridge'),
  [KostCommonFacility.TV]: i18n.t('facility_tv'),
  [KostCommonFacility.WaterDispenser]: i18n.t('facility_water_dispenser'),
  [KostCommonFacility.LivingRoom]: i18n.t('facility_living_room'),
  [KostCommonFacility.Gym]: i18n.t('facility_gym'),
  [KostCommonFacility.SwimmingPool]: i18n.t('facility_swimming_pool'),
};

export const KOST_COMMON_FACILITY_OPTIONS = [
  KostCommonFacility.Wifi,
  KostCommonFacility.CarParking,
  KostCommonFacility.MotorcycleParking,
  KostCommonFacility.WashingMachine,
  KostCommonFacility.Laundry,
  KostCommonFacility.Security,
  KostCommonFacility.PrayerRoom,
  KostCommonFacility.Fridge,
  KostCommonFacility.TV,
  KostCommonFacility.WaterDispenser,
  KostCommonFacility.LivingRoom,
  KostCommonFacility.Gym,
  KostCommonFacility.SwimmingPool,
].map((key) => ({
  key,
  text: KOST_COMMON_FACILITY_TEXT[key],
}));

export const KOST_FACILITY_TEXT = {
  ...KOST_COMMON_FACILITY_TEXT,
  ...KOST_ROOM_FACILITY_TEXT,
};

export enum KostRules {
  Access24H = 'access24h',
  Couple = 'couple',
  Max2Pax = 'max2pax',
  Pets = 'pets',
  Employee = 'employee',
  Kids = 'kids',
}

export const KOST_RULES_TEXT = {
  [KostRules.Access24H]: i18n.t('kost_rules_access_24h'),
  [KostRules.Couple]: i18n.t('kost_rules_couple'),
  [KostRules.Max2Pax]: i18n.t('kost_rules_max_2_pax'),
  [KostRules.Pets]: i18n.t('kost_rules_pets'),
  [KostRules.Employee]: i18n.t('kost_rules_employee'),
  [KostRules.Kids]: i18n.t('kost_rules_kids'),
};

export const KOST_RULES_OPTIONS = [
  KostRules.Access24H,
  KostRules.Couple,
  KostRules.Max2Pax,
  KostRules.Pets,
  KostRules.Employee,
  KostRules.Kids,
].map((key) => ({
  key,
  text: KOST_RULES_TEXT[key],
}));
