import { PublishStatus } from '@p1/types';
import i18n from '../i18n';

export const PUBLISH_STATUS_TEXT = {
  [PublishStatus.Pending]: i18n.t('status_pending'),
  [PublishStatus.Published]: i18n.t('status_published'),
  [PublishStatus.Rejected]: i18n.t('status_rejected'),
  [PublishStatus.Inactive]: i18n.t('status_inactive'),
  [PublishStatus.Deleted]: i18n.t('status_deleted'),
};

export const PUBLISH_STATUS_OPTIONS = [
  PublishStatus.Pending,
  PublishStatus.Published,
  PublishStatus.Rejected,
  PublishStatus.Inactive,
  PublishStatus.Deleted,
].map((value) => ({
  value,
  label: PUBLISH_STATUS_TEXT[value],
}));
