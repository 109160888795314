import { Language, createI18n } from '@p1/i18n';
import ID from '../assets/strings/id.json';
import EN from '../assets/strings/en.json';

const dictionary = {
  [Language.ID]: ID as Record<string, string>,
  [Language.EN]: EN as Record<string, string>,
};

export default createI18n(dictionary);
