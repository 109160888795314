import i18n from '../i18n';
import {
  Certificate,
  IklanType,
  ListingStatus,
  PropertyType,
  RentPeriod,
  LocationTagType,
  Price,
  KostGender,
  PremiumListingLocation,
} from '@p1/types';

export const IKLAN_TYPE_TEXT = {
  [IklanType.Rent]: i18n.t('rent'),
  [IklanType.Sell]: i18n.t('sell'),
};

export const IKLAN_TYPE_OPTIONS = [
  { value: IklanType.Rent, label: i18n.t('rent') },
  { value: IklanType.Sell, label: i18n.t('sell') },
];

export const PROPERTY_TYPE_TEXT: Record<PropertyType, string> = {
  [PropertyType.Apartment]: i18n.t('apartment'),
  [PropertyType.House]: i18n.t('house'),
  [PropertyType.Land]: i18n.t('land'),
  [PropertyType.Shophouse]: i18n.t('shophouse'),
  [PropertyType.Warehouse]: i18n.t('warehouse'),
  [PropertyType.Factory]: i18n.t('factory'),
  [PropertyType.Kiosk]: i18n.t('kiosk'),
  [PropertyType.Office]: i18n.t('office'),
  [PropertyType.Villa]: i18n.t('villa'),
  [PropertyType.BusinessPlace]: i18n.t('business_place'),
  [PropertyType.Building]: i18n.t('building'),
  [PropertyType.Hotel]: i18n.t('hotel'),
  [PropertyType.Kost]: i18n.t('kost'),
};

export const LOCATION_TAG_TYPE_TEXT = {
  [LocationTagType.Area]: i18n.t('area'),
  [LocationTagType.Street]: i18n.t('street'),
  [LocationTagType.Building]: i18n.t('location_tag_building'),
  [LocationTagType.Hospital]: i18n.t('hospital'),
  [LocationTagType.Campus]: i18n.t('campus'),
  [LocationTagType.School]: i18n.t('school'),
  [LocationTagType.Airport]: i18n.t('airport'),
  [LocationTagType.Station]: i18n.t('station'),
  [LocationTagType.Mall]: i18n.t('mall'),
  [LocationTagType.HousingComplex]: i18n.t('housing_complex'),
  [LocationTagType.Market]: i18n.t('market'),
  [LocationTagType.Landmark]: i18n.t('landmark'),
  [LocationTagType.Stop]: i18n.t('stop'),
  [LocationTagType.Other]: i18n.t('other'),
};

export const LOCATION_TAG_TYPE_OPTIONS = [
  LocationTagType.Area,
  LocationTagType.Street,
  LocationTagType.Building,
  LocationTagType.Hospital,
  LocationTagType.Campus,
  LocationTagType.School,
  LocationTagType.Airport,
  LocationTagType.Station,
  LocationTagType.Mall,
  LocationTagType.HousingComplex,
  LocationTagType.Market,
  LocationTagType.Landmark,
  LocationTagType.Stop,
  LocationTagType.Other,
].map((value) => ({
  value,
  label: LOCATION_TAG_TYPE_TEXT[value],
}));

export const PROPERTY_TYPE_OPTIONS = [
  PropertyType.Apartment,
  PropertyType.House,
  PropertyType.Kost,
  PropertyType.Land,
  PropertyType.Shophouse,
  PropertyType.Warehouse,
  PropertyType.Factory,
  PropertyType.Kiosk,
  PropertyType.BusinessPlace,
  PropertyType.Building,
  PropertyType.Office,
  PropertyType.Hotel,
  PropertyType.Villa,
].map((val) => ({
  value: val,
  label: PROPERTY_TYPE_TEXT[val],
}));

export const CERTIFICATE_TEXT = {
  [Certificate.SHM]: i18n.t('certificate_shm'),
  [Certificate.AJB]: i18n.t('certificate_ajb'),
  [Certificate.HGB]: i18n.t('certificate_hgb'),
  [Certificate.STT]: i18n.t('certificate_stt'),
  [Certificate.HGU]: i18n.t('certificate_hgu'),
  [Certificate.HakPakai]: i18n.t('certificate_hak_pakai'),
  [Certificate.Girik]: i18n.t('certificate_girik'),
  [Certificate.PPJB]: i18n.t('certificate_ppjb'),
  [Certificate.Adat]: i18n.t('certificate_adat'),
  [Certificate.Other]: i18n.t('certificate_other'),
};

export const getCertificateOptions = (type: PropertyType) => {
  switch (type) {
    case PropertyType.House:
    case PropertyType.Kost:
      return [
        Certificate.SHM,
        Certificate.HGB,
        Certificate.AJB,
        Certificate.PPJB,
        Certificate.Girik,
        Certificate.Adat,
        Certificate.Other,
      ].map((val: Certificate) => ({
        value: val,
        label: CERTIFICATE_TEXT[val],
      }));
    case PropertyType.Land:
    case PropertyType.Shophouse:
    case PropertyType.Warehouse:
    case PropertyType.Factory:
    case PropertyType.Kiosk:
    case PropertyType.Building:
    case PropertyType.BusinessPlace:
    case PropertyType.Villa:
    case PropertyType.Hotel:
      return [
        Certificate.SHM,
        Certificate.HGB,
        Certificate.HGU,
        Certificate.AJB,
        Certificate.HakPakai,
        Certificate.Girik,
        Certificate.PPJB,
        Certificate.Other,
      ].map((val: Certificate) => ({
        value: val,
        label: CERTIFICATE_TEXT[val],
      }));
    default:
      return [];
  }
};

export const CERTIFICATE_OPTIONS = Object.values(PropertyType).reduce(
  (acc, cur) => {
    acc[cur] = getCertificateOptions(cur);
    return acc;
  },
  {} as Record<PropertyType, { value: Certificate; label: string }[]>
);

export const OTHER_PROPERTY_ID = {
  [PropertyType.Apartment]: 'other-apartment',
  [PropertyType.House]: 'other-house',
};

export const LISTING_STATUS_TEXT: Record<ListingStatus, string> = {
  [ListingStatus.Pending]: i18n.t('status_pending'),
  [ListingStatus.Published]: i18n.t('status_published'),
  [ListingStatus.Rejected]: i18n.t('status_rejected'),
  [ListingStatus.Inactive]: i18n.t('status_inactive'),
  [ListingStatus.Deleted]: i18n.t('status_deleted'),
};

export const LISTING_STATUS_OPTIONS = [
  ListingStatus.Pending,
  ListingStatus.Published,
  ListingStatus.Rejected,
  ListingStatus.Inactive,
  ListingStatus.Deleted,
].map((value) => ({
  value,
  label: LISTING_STATUS_TEXT[value],
}));

export const LISTING_STATUS_OPTIONS_WITHOUT_DELETED =
  LISTING_STATUS_OPTIONS.filter(({ value }) => value !== ListingStatus.Deleted);

export const PERIOD_TEXT = {
  [RentPeriod.Daily]: i18n.t('daily'),
  [RentPeriod.Monthly]: i18n.t('monthly'),
  [RentPeriod.Weekly]: i18n.t('weekly'),
  [RentPeriod.Yearly]: i18n.t('yearly'),
};

export const RENT_PERIOD_TEXT = {
  [RentPeriod.Daily]: i18n.t('daily_rent'),
  [RentPeriod.Monthly]: i18n.t('monthly_rent'),
  [RentPeriod.Weekly]: i18n.t('weekly_rent'),
  [RentPeriod.Yearly]: i18n.t('yearly_rent'),
};

export const DEFAULT_RENT_PRICE_OPTIONS = [
  { value: RentPeriod.Daily, label: i18n.t('rent_daily') },
  { value: RentPeriod.Weekly, label: i18n.t('rent_weekly') },
  { value: RentPeriod.Monthly, label: i18n.t('rent_monthly') },
  { value: RentPeriod.Yearly, label: i18n.t('rent_yearly') },
];

export const RENT_PRICE_OPTIONS = [
  { value: Price.Daily, label: i18n.t('rent_daily') },
  { value: Price.Weekly, label: i18n.t('rent_weekly') },
  { value: Price.Monthly, label: i18n.t('rent_monthly') },
  { value: Price.Yearly, label: i18n.t('rent_yearly') },
];

export const KOST_GENDER_TEXT = {
  [KostGender.Male]: i18n.t('kost_male'),
  [KostGender.Female]: i18n.t('kost_female'),
  [KostGender.Mixed]: i18n.t('kost_mixed'),
};

export const KOST_GENDER_OPTIONS = [
  KostGender.Male,
  KostGender.Female,
  KostGender.Mixed,
].map((key) => ({
  value: key,
  label: KOST_GENDER_TEXT[key],
}));

export const PREMIUM_LISTING_LOCATION_TEXT = {
  [PremiumListingLocation.Home]: i18n.t('premium_listing_location_home'),
  [PremiumListingLocation.List]: i18n.t('premium_listing_location_list'),
};

export const PREMIUM_LISTING_LOCATION_OPTIONS = [
  PremiumListingLocation.Home,
  PremiumListingLocation.List,
].map((key) => ({
  value: key,
  label: PREMIUM_LISTING_LOCATION_TEXT[key],
}));
